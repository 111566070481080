:root {
  --react-pdf-text-layer: 1;
}

.textLayer {
  text-align: initial;
  text-size-adjust: none;
  forced-color-adjust: none;
  line-height: 1;
  position: absolute;
  inset: 0;
  overflow: hidden;
}

.textLayer span, .textLayer br {
  color: #0000;
  white-space: pre;
  cursor: text;
  transform-origin: 0 0;
  z-index: 1;
  position: absolute;
}

.textLayer span.markedContent {
  height: 0;
  top: 0;
}

.textLayer .highlight {
  background-color: #b400aa;
  border-radius: 4px;
  margin: -1px;
  padding: 1px;
}

.textLayer .highlight.appended {
  position: initial;
}

.textLayer .highlight.begin {
  border-radius: 4px 0 0 4px;
}

.textLayer .highlight.end {
  border-radius: 0 4px 4px 0;
}

.textLayer .highlight.middle {
  border-radius: 0;
}

.textLayer .highlight.selected {
  background-color: #006400;
}

.textLayer br::selection {
  background: none;
}

.textLayer .endOfContent {
  z-index: 0;
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
  display: block;
  position: absolute;
  inset: 100% 0 0;
}

.textLayer .endOfContent.active {
  top: 0;
}

:root {
  --root-top-pad: 0;
  --root-left-pad: 0;
  --root-right-pad: 0;
  --dvh: 100vh;
  --dvw: 100vw;
}

@supports (padding-bottom: env(safe-area-inset-bottom)) {
  body {
    padding: 0 env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
  }

  :root {
    --root-top-pad: env(safe-area-inset-top);
    --root-left-pad: env(safe-area-inset-left);
    --root-right-pad: env(safe-area-inset-right);
    --dvh: calc(100vh - calc(env(safe-area-inset-top)  + env(safe-area-inset-bottom)));
    --dvw: calc(100vw - calc(env(safe-area-inset-left)  + env(safe-area-inset-right)));
  }
}

@supports (height: 100dvh) {
  :root {
    --dvh: calc(100dvh - calc(env(safe-area-inset-top)  + env(safe-area-inset-bottom)));
    --dvw: calc(100dvw - calc(env(safe-area-inset-left)  + env(safe-area-inset-right)));
  }
}

.dynamic-pdf-page.resize > .react-pdf__Page__canvas {
  width: calc(100% + 1px) !important;
  height: calc(100% + 1px) !important;
}

.dynamic-pdf-page.hidden {
  display: none;
}

/*# sourceMappingURL=index.6bf684fb.css.map */
