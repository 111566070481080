:root {
  --root-top-pad: 0px;
  --root-left-pad: 0px;
  --root-right-pad: 0px;
  --dvh: 100vh;
  --dvw: 100vw;
}

@supports (padding-bottom: env(safe-area-inset-bottom)) {
  body {
    padding: 0 env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
  }

  :root {
    --root-top-pad: env(safe-area-inset-top);
    --root-left-pad: env(safe-area-inset-left);
    --root-right-pad: env(safe-area-inset-right);
    --dvh: calc(100vh - calc(env(safe-area-inset-top) + env(safe-area-inset-bottom)));
    --dvw: calc(100vw - calc(env(safe-area-inset-left) + env(safe-area-inset-right)));
  }
}

@supports (height: 100dvh) {
  :root {
    --dvh: calc(100dvh - calc(env(safe-area-inset-top) + env(safe-area-inset-bottom)));
    --dvw: calc(100dvw - calc(env(safe-area-inset-left) + env(safe-area-inset-right)));
  }
}
